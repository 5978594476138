import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import ProgressiveImg from "../ProgressiveImg";

const TransportationMarketingBanner = ({
	onClick,
	className,
	disableClick,
	imageCloudinaryOptions,
	imageCloudinarySizes,
	ImageComponent,
	imageThumbnail,
	imageSrc,
}) => {
	return (
		<div
			style={disableClick ? { cursor: "initial" } : null}
			className={classNames("transportation-marketing-banner", className)}
			onClick={disableClick ? null : onClick}
		>
			<ImageComponent
				sizes={imageCloudinarySizes}
				cloudinaryOptions={imageCloudinaryOptions}
				src={imageSrc}
				thumbnail={imageThumbnail}
			/>
		</div>
	);
};

TransportationMarketingBanner.propTypes = {
	className: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	disableClick: PropTypes.bool,
	imageCloudinaryOptions: PropTypes.array,
	imageCloudinarySizes: PropTypes.object,
	ImageComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
	imageThumbnail: PropTypes.string,
	imageSrc: PropTypes.string,
	onClick: PropTypes.func,
};

TransportationMarketingBanner.defaultProps = {
	disableClick: false,
	ImageComponent: ProgressiveImg,
	onClick: null,
};

export default React.memo(TransportationMarketingBanner);
